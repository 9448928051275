import React from 'react';
import {
  Link
} from "react-router-dom";

const SiteFooterComponent = (props) => {

	return (
	<div>

    <footer className="main-footer footer-style-two">

        <div className="footer-bottom">
            <div className="auto-container">
                <div className="inner-container">
                    <div className="copyright-text">
						&copy; <Link className="" to={`/`}>AlgorithmXYZ.com</Link>  2023 | All Rights Reserved
					</div>

                    <ul className="footer-nav">
                        <li>
							<Link className="" to={`/site/contact`}>Contact Us</Link>
						</li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>

	</div>
	);

};

export default SiteFooterComponent;
