import React from 'react';
import {
  Link
} from "react-router-dom";

import AuthenticationService from '../services/authenticationservice.js';

const SiteHeaderComponent = (props) => {

	const authenticationService = new AuthenticationService();

	return (
<div>

    <header className="main-header header-style-two">

        <div className="header-lower">

            <div className="main-box">
                <div className="logo-box">
                    <div className="logo">
						<Link to={`/`}> <img src="/assets/SiteTheme/images/logo-2.png" alt="" title="AlgorithmXYZ" /> </Link>
					</div>
                </div>

                <div className="nav-outer">    
                    <nav className="nav main-menu">
                      <ul className="navigation">
						  <li> <Link to={`/`}>Home</Link> </li>
						  <li> <Link to={`/site/about`}>About</Link> </li>
						  <li> <Link to={`/site/services`}>Services</Link> </li>
                          <li> <Link to={`/site/contact`}>Contact</Link> </li>
                      </ul>
                    </nav>
                </div>

                <div className="outer-box">
					<Link className="theme-btn btn-style-two light" to={`/site/contact`}>
						<span className="btn-title">Get a quote <i className="fa fa-arrow-right"></i></span>
					</Link>

                    <div className="mobile-nav-toggler"><span className="icon lnr-icon-bars"></span></div>
                </div>
            </div>
        </div>

        <div className="mobile-menu">
            <div className="menu-backdrop"></div>
        
            <nav className="menu-box">
                <div className="upper-box">
                    <div className="nav-logo">
						<Link to={`/`}> <img src="/assets/SiteTheme/images/logo-2.png" alt="" title="" /> </Link>
					</div>
                    <div className="close-btn"><i className="icon fa fa-times"></i></div>
                </div>
        
                <ul className="navigation clearfix">
					 <li> <Link to={`/`}>Home</Link> </li>
					 <li> <Link to={`/site/about`}>About</Link> </li>
				     <li> <Link to={`/site/services`}>Services</Link> </li>
                     <li> <Link to={`/site/contact`}>Contact</Link> </li>
                </ul>

                <ul className="contact-list-one">
                </ul>
        
                <ul className="social-links">
                </ul>
            </nav>
        </div>

        <div className="sticky-header">
            <div className="auto-container">
                <div className="inner-container">
                    <div className="logo">
						<Link to={`/`}> <img src="/assets/SiteTheme/images/logo.png" alt="" title="" /> </Link>
                    </div>
        
                    <div className="nav-outer">

                        <nav className="main-menu">
                            <div className="navbar-collapse show collapse clearfix">
                                <ul className="navigation clearfix">
								  <li> <Link to={`/`}>Home</Link> </li>
								  <li> <Link to={`/site/about`}>About</Link> </li>
								  <li> <Link to={`/site/services`}>Services</Link> </li>
				                  <li> <Link to={`/site/contact`}>Contact</Link> </li>
                                </ul>
                            </div>
                        </nav>
        
                        <div className="mobile-nav-toggler"><span className="icon lnr-icon-bars"></span></div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div className="hidden-bar-back-drop"></div>

    <section className="hidden-bar">
        <div className="inner-box">
            <div className="upper-box">
                <div className="nav-logo">
					<Link to={`/`}> <img src="/assets/SiteTheme/images/logo-2.png" alt="" title="" /> </Link>
				</div>

                <div className="close-btn"><i className="icon fa fa-times"></i></div>
            </div>

            <div className="text-box">
                <h4 className="title"></h4>
                <div className="text"></div>
            </div>

            <ul className="contact-list-one">

            </ul>

            <ul className="social-links">

            </ul>
        </div>
    </section>


</div>
	);

};

export default SiteHeaderComponent;
