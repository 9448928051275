import React, { Component } from 'react';
import {
  Outlet,
} from "react-router-dom";

const SiteIndexComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Home";

	return (
	<div>

		<section className="banner-section-two">
        <div className="bg bg-image index_bg">
            <div className="bg bg-overlay"></div>
        </div>
        <div className="auto-container">
            <div className="content-box">
                <h1 className="title wow fadeInUp" data-wow-delay="600ms">Software for a better World!</h1>
                <div className="text wow fadeInUp" data-wow-delay="900ms">A software company involved in everything that matters.</div>
            </div>
        </div>
		</section>

	</div>
	);

};

export default SiteIndexComponent;
