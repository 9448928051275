import {
	useLocation,
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

//HEADER + FOOTER + MISC
import SiteHeaderComponent from './pages/siteheader.js';
import SiteFooterComponent from './pages/sitefooter.js';
import ProtectedRoute from './helpers/protectedroute.js';
//import SiteComponent from './pages/sitecomponent.js';

//SITE
import SiteIndexComponent from './pages/site/siteindex.js';
import SiteContactComponent from './pages/site/sitecontact.js';
import SiteAboutComponent from './pages/site/siteabout.js';
import SiteServicesComponent from './pages/site/siteservices.js';


const NoMatchComponent = () => {
  return (
    <div>
      <h2>404!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
};

function App() {

	return (
	<div className="page-wrapper">

		<div className="preloader"></div>

		<Router>
			<SiteHeaderComponent />
			
			<Routes>

				<Route path="/site">

					<Route path="about">
						<Route index element={<SiteAboutComponent />} />
					</Route>

					<Route path={`services`} element={<SiteServicesComponent />}></Route>
					<Route path={`contact`} element={<SiteContactComponent />}></Route>
				</Route>

				<Route exact path="/" element={<SiteIndexComponent />}></Route>
				<Route path="*" element={<NoMatchComponent />}></Route>

			</Routes>

			<SiteFooterComponent />

		</Router>
	</div>
	);
}

export default App;
