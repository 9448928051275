import React, { Component, useEffect, useReducer, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import AlertComponent from '../alertcomponent.js';

const SiteServicesComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Services";

	return (
  <div>

	<section className="page-title index_services_bg">
		<div className="auto-container">
			<div className="title-outer d-sm-flex align-items-center justify-content-sm-between">
				<h1 className="title">Services</h1>
			</div>
		</div>
	</section>

  <section className="services-section-two">
      <div className="auto-container">
          <div className="row">

              <div className="service-block-two col-lg-4 col-md-6">
                  <div className="inner-box">
                      <div className="icon"><i className="flaticon-diploma"></i></div>
                      <h4 className="title">B2B Software</h4>
                      <div className="text">We know from experience, business customers are looking for reliable software that works, with new features constantly being released. We have 10+ years of experience doing just that.</div>
                  </div>
              </div>

              <div className="service-block-two col-lg-4 col-md-6">
                  <div className="inner-box">
                      <div className="icon"><i className="flaticon-cog-1"></i></div>
                      <h4 className="title">Quality Assurance</h4>
                      <div className="text">All our software is extensively tested before release. We have a full team of QA testers to find bugs before you do.<br /> <br /> <br /></div>
                  </div>
              </div>

              <div className="service-block-two col-lg-4 col-md-6">
                  <div className="inner-box">
                      <div className="icon"><i className="flaticon-approved"></i></div>
                      <h4 className="title">Cloud Reliability</h4>
                      <div className="text">Avoid having your critical software being offline while needed through our own Kubernetes Clusters designed for reliability and scale. <br /><br /> </div>
                  </div>
              </div>

          </div>
      </div>
  </section>

  </div>
	);

};

export default SiteServicesComponent;
