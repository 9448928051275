import React from 'react';

const SiteAboutComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "About";

	return (
  <div>

	<section className="page-title index_about_bg">
		<div className="auto-container">
			<div className="title-outer d-sm-flex align-items-center justify-content-sm-between">
				<h1 className="title">About Us</h1>
			</div>
		</div>
	</section>

   <section className="about-section-two">
      <div className="auto-container">
          <div className="row">

              <div className="content-column col-lg-6 order-lg-2 wow fadeInRight" data-wow-delay="600ms">
                  <div className="inner-column">
                      <div className="sec-title">
                          <span className="sub-title">About Us</span>
                          <h2>A software company building software to make your business run smoother!</h2>
                          <div className="text">Looking to grow your business? We have a large variety of software products, managing HR, Billing, Car Dealerships, Customer Service Solutions, Cloud Platforms and more.</div>
                      </div>

                      <div className="info-block-two">
                          <div className="inner">
                              <div className="icon-box"><i className="icon flaticon-puzzle"></i></div>
                              <h4 className="title">Peak Performance</h4>
                              <div className="text">Our software products are designed for speed, so you can spend your time growing your business.</div>
                          </div>
                      </div>

                      <div className="info-block-two">
                          <div className="inner">
                              <div className="icon-box"><i className="icon flaticon-production"></i></div>
                              <h4 className="title">Solutions Sphere</h4>
                              <div className="text">Our software solutions are designed to help complete your business, and can be easily extended for additional features without difficulty.</div>
                          </div>
                      </div>
                  </div>
              </div>


              <div className="image-column col-lg-6">
                  <div className="inner-column wow fadeInLeft">
                      <span className="icon-circle"></span>
                      <span className="icon-dots-2"></span>
                      <figure className="image overlay-anim wow fadeInUp"><img src="/assets/SiteTheme/images/resource/about2-1.jpg" alt="" /></figure>
                      <div className="exp-box bounce-y">
                          <div className="inner">
                              <div className="icon-box">
                                  <span className="icon fa flaticon-target"></span>
                                  <h2 className="title">10+</h2>
                              </div>
                              <p className="text">Years Of Experiences</p>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div>
  </section>

  </div>
	);

};

export default SiteAboutComponent;
