import React, { Component, useEffect, useReducer, useState } from 'react';
import {
  useLocation, useNavigate, useParams
} from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import AlertComponent from '../alertcomponent.js';

const SiteContactComponent = (props) => {

	document.title = process.env.REACT_APP_SITE_NAME + " - " + "Contact";

	//REACT HOOK FORM INIT
	const { control, handleSubmit, register, reset, formState: { errors } } = useForm({
		mode: "onChange",
    	defaultValues:
		{
		}
	});

	const [state, setState] = useState({
			showAlert: false,
			responseStatus: false,
			responseMessage: "",
			apiRequestLoadOK: false,
			isPageLoaded: false,
			isAPIFailure: false,
			formSubmitted: false
		});

	const navigate = useNavigate();

	useEffect(() => {


	}, []);

	const getFormValidStatus = (e) => {
		return formState.isFormValid;
	}

	const onSubmit = async (values) => {

		let data = {
        "name": values.name,
        "email": values.email,
        "password": values.password
		}

		console.log("SUBMIT VALUES=>", values);

		setState({...state, formSubmitted: true});
	}

	return (
  <div>

	<section className="page-title index_contact_bg">
		<div className="auto-container">
			<div className="title-outer d-sm-flex align-items-center justify-content-sm-between">
				<h1 className="title">Contact Us</h1>
			</div>
		</div>
	</section>

	<section className="contact-details">
		<div className="container ">
			<div className="row">
				<div className="col-xl-7 col-lg-6">
					<div className="sec-title">
						<span className="sub-title">Send us email</span>
						<h2>Feel free to write</h2>
					</div>

					<form id="contact_form" name="contact_form" className="" action="includes/sendmail.php" method="post">
						<div className="row">
							<div className="col-sm-6">
								<div className="mb-3">
									<input name="form_name" className="form-control" type="text" placeholder="Enter Name" />
								</div>
							</div>
							<div className="col-sm-6">
								<div className="mb-3">
									<input name="form_email" className="form-control required email" type="email" placeholder="Enter Email" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-sm-6">
								<div className="mb-3">
									<input name="form_subject" className="form-control required" type="text" placeholder="Enter Subject" />
								</div>
							</div>
							<div className="col-sm-6">
								<div className="mb-3">
									<input name="form_phone" className="form-control" type="text" placeholder="Enter Phone" />
								</div>
							</div>
						</div>
						<div className="mb-3">
							<textarea name="form_message" className="form-control required" rows="7" placeholder="Enter Message"></textarea>
						</div>
						<div className="mb-5">
							<input name="form_botcheck" className="form-control" type="hidden" value="" />
							<button type="submit" className="theme-btn btn-style-one" data-loading-text="Please wait..."><span className="btn-title">Send message</span></button>
							<button type="reset" className="theme-btn btn-style-one bg-theme-color5"><span className="btn-title">Reset</span></button>
						</div>
					</form>

				</div>
				<div className="col-xl-5 col-lg-6">
					<div className="contact-details__right">
					</div>
				</div>
			</div>
		</div>
	</section>





  </div>
	);
};

export default SiteContactComponent;
